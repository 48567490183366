<template>
  <div id="page">

    <b-list-group>
      <div class="tabela">

        <h2>Consultar Disponibilidade</h2>

        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Data Entrada</label>
            <b-form-input
              type="date"
              id="datepicker-dateformat2"
              v-model="dataIn"
              locale="pt"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Data Saida</label>
            <b-form-input
              type="date"
              id="datepicker-dateformat2"
              v-model="dataOut"
              locale="pt"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Quatidade hóspedes</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="qtdHospedes"
              placeholder="0"
            ></b-form-input>
          </div>


          <div class="form-group col-lg-3">
            <b-button class="botao" variant="success" block @click="getQuartos">Buscar</b-button>
          </div>

        </div>
          
      </div>



      <div class="tabela" v-if="exibirQuartos">
        <b-table
          id="table-quartos" 
          striped
          hover 
          ref="selectableTable"
          class="text-left" 
          :items="quartos" 
          :fields="fieldsQuartos" 
          :busy="load"
          value="true"
          outlined
          >              

          <template #table-busy>
              <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong></strong>
              </div>
          </template>

          <template #cell(visao)="data">
            <img src="../assets/vista-sol.png" alt="vista" height="30px" v-if="data.item.visao == 'MAR'"/>
            <img src="../assets/vista-vegetacao.png" alt="vista" height="30px" v-if="data.item.visao == 'VEGETAÇÃO'"/>
            <img src="../assets/vista-cidade.png" alt="vista" height="30px" v-if="data.item.visao == 'CIDADE'"/>
            <img src="../assets/vista-cadeirante.jpg" alt="vista" height="30px" v-if="data.item.visao == 'CADEIRANTE'"/>
          </template>


          <template #cell(reservas)="row">
            <!-- <b-icon class="cursor" icon="people-fill" variant="info" style="width: 18px; height: 18px;" @click="selecionarQuarto(row.item)"></b-icon> -->
            <b-icon class="cursor" icon="bootstrap-reboot" variant="info" style="width: 25px; height: 25px;" @click="row.toggleDetails" v-if="row.item.status == 'Ocupado'"></b-icon>
          </template>


          <template #row-details="row">

            <b-card class="table-hospedes-sub">

              <div>
                <b-table
                  striped
                  hover 
                  ref="selectableTable"
                  class="text-left" 
                  :items="row.item.reservas" 
                  :fields="fieldsReservas" 
                  value="true"
                  unchecked-value="false"
                  small
                  outlined
                  >              

                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong></strong>
                      </div>
                  </template>

                </b-table>
              </div>

            </b-card>
          </template>


        </b-table>
      </div>

      


    </b-list-group>


    <!-- MODAL RESUMO -->
    <div>
      <b-modal ref="modal-resumo" hide-footer title="Resumo de quartos" size="sm">

        <div>
          
          <div class="text-right col-lg-12 mt-4">
            <div class="mt-0">Disponível: <strong>{{ this.quartosDisponiveis }}</strong></div>   
            <div class="mt-0">Ocupado: <strong>{{ this.quartosOcupados }}</strong></div>   
            <div class="mt-0">Total: <strong>{{ this.quartosTotais }}</strong></div>   
            <div class="mt-0">Cadeirantes: <strong>{{ this.quartosCadeirantes }}</strong></div>   
            <div class="mt-0">Cama de casal: <strong>{{ this.quartosCamaCasal }}</strong></div>   
            <div class="mt-0">Acomodações disponíveis: <strong>{{ this.quartosAcomodacoesDisponiveis }}</strong></div>   
            <div class="mt-0">Quartos disponíveis PET: <strong>{{ this.quartosDisponiveisPET }}</strong></div>   
          </div>

        </div>
      </b-modal>
    </div>
    <!-- MODAL RESUMO FIM-->



    <a href="#" class="btnTop"></a>



  </div>
</template>

<script>
import dayjs from "dayjs"
// import axios from "axios";
import api from "../services/api";
import Quartos from './quartos/Quartos'

export default {
  name: "ConsultarDisponibilidade",
  components: [
    Quartos
  ],

  data() {
    return {

      colonia: '',
      filtro: '',
      dataIn: null,
      dataOut: null,
      qtdHospedes: 0,

      quartos: [],
      fieldsQuartos: [
        {key: 'id', label: 'Número'},
        {key: 'visao', label: 'Visão'},
        {key: 'andar', label: 'Andar'},
        {key: 'predio', label: 'Prédio'},
        {key: 'solteiro', label: 'Solteiro', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'beliche', label: 'Beliche', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'casal', label: 'Casal', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'totalAcomodacoes', label: 'Total acomod.', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'status', label: 'Status'},
        {key: 'reservas', label: ''}
      ],

      fieldsReservas: [
        { key: "cod", label: "Nº Reserva", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "dataIn", label: "Entrada", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "qtdHospedes", label: "Hspedes", sortable: true },
      ],

      items: [], 

      load: false,
      exibirQuartos: false,

      quartosDisponiveis: 0,
      quartosOcupados: 0,
      quartosTotais: 0,
      quartosCadeirantes: 0,
      quartosCamaCasal: 0,
      quartosAcomodacoesDisponiveis: 0,
      quartosDisponiveisPET: 0

    };
  },



  created() {

    const user = this.$store.state.auth.user 
    this.exibirQuartos = user.userLocal != 'Sede' ? true : false

    this.colonia = this.$store.state.colonia 
    this.dataIn = dayjs().format('YYYY-MM-DD')
    this.dataOut = dayjs().format('YYYY-MM-DD')
    // this.dataOut = '1900-01-01'
    this.getQuartos();
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },
    


    getQuartos() {

      this.load = true
      
      const url = !this.dataIn
                  ? `reservas/quartos/0/1900-01-01/1900-01-01/${this.colonia}`
                  : `reservas/quartos/${this.qtdHospedes}/${this.dataIn}/${this.dataOut}/${this.colonia}`
      
      api.get(url)
      .then(res => {
        
        this.quartos = res.data.filter(item => item.totalAcomodacoes >= this.qtdHospedes)
        this.load = false


        // Regra para exibir resumo
        //---------------------------------
        this.quartosDisponiveis = 0
        this.quartosOcupados = 0
        this.quartosTotais = 0
        this.quartosCadeirantes = 0
        this.quartosCamaCasal = 0
        this.quartosAcomodacoesDisponiveis = 0
        this.quartosDisponiveisPET = 0
        this.quartos.forEach(item => {
          if (item.status == 'Disponível') this.quartosDisponiveis++
          if (item.status == 'Ocupado') this.quartosOcupados++
          if (item.status == 'Disponível' && item.visao == 'CADEIRANTE') this.quartosCadeirantes++
          if (item.status == 'Disponível' && item.casal > 0) this.quartosCamaCasal++
          if (item.status == 'Disponível') this.quartosAcomodacoesDisponiveis += item.totalAcomodacoes
          if (item.status == 'Disponível' && item.andar == '3º Andar') this.quartosDisponiveisPET++
          
          this.quartosTotais++
        })
        
        // if (!this.exibirQuartos)
        this.$refs["modal-resumo"].show()
        //---------------------------------

      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'error', 5000)
      });
    }, 





     resetForm() {

      const form = this.form
      
      // this.filtroNomeSocio = null
      // this.socioSituacaoNoCadastro = null
      
      for (var proper in form){
        
        form[proper] = null
      }
      
    },



  },

  computed: {

      filtrar() {

          // console.log('opaa')
          
          // if (this.filtro.trim() == '') return this.items
              
          // let listaFiltrada = []
          // this.items.forEach((item) => {
          //     if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
          //     if(item.saldo && item.saldo.toString().match(this.filtro)) listaFiltrada.push(item) 
          // })

          // return listaFiltrada
          return this.items
      },
  },

  
};
</script>

<style scoped>

/* Levar ao topo */
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
section {
    height: 0;
    background-image: linear-gradient(red, blue);
}
.btnTop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    background-color: #8f23d6;
    text-decoration: none;
}
.btnTop::after {
    content: "↑";
    font-size: 32px;
    font-weight: bold;
    color: aliceblue;
    transition: margin-top 250ms;
}
.btnTop:hover::after {
    margin-top:-8px;
}
/* Levar ao topo FIM */



#page h2 {
  margin-top: 60px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

h5 {
  margin-top: 65px;
  margin-bottom: 5px;
  color: #8f23d6;
}

.divider {
  /* margin-top: 25px; */
  margin-bottom: 35px;
  height: 2px;
  width: 100%;
  background-color: #8f23d6;
}


.tabela {
  width: 80vw;
  margin-top: 20px;
}

img, .cursor {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

#input-1{
  background-color: #fff;
}

.botao{
  margin-top: 32px;
}


@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

}


</style>