import api from "@/services/api";
import { acessoRestrito } from "@/global";
import { validaCelular } from "./Reservas.services";
import dayjs from 'dayjs'
import axios from "axios";
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import Quartos from '../quartos/Quartos'
import ModalMostrarValorCheckIn from './modal/ModalMostrarValorCheckIn'
import ModalCreditos from './modal/ModalCreditos'
import ModalVeiculos from './modal/ModalVeiculos'
import Comentarios from './Comentarios'
import ModalFaturarPersonalizado from './modal/ModalFaturarPersonalizado'
import ModalImprimirReserva from '../relatorios/modalOpcoesImpressoes/ModalOpcoes'
import ModalFecharCartao from '../bar/creditosCartoes/ModalFecharCartao'
import ModalCheckOutAntecipado from './modal/ModalCheckOutAntecipado'
import ModalValorAReceberPosCheckIn from './modal/ModalValorAReceberPosCheckIn'
import ModalSumarioReservasHospedes from './modal/ModalSumarioReservasHospedes'
import ModalQuartoInfo from './modal/ModalQuartoInfo'

export default {
  name: "Reservas",
  components: { 
    DatePicker,
    Quartos,
    ModalMostrarValorCheckIn,
    ModalCreditos,
    ModalVeiculos,
    Comentarios,
    ModalFaturarPersonalizado,
    ModalImprimirReserva,
    ModalFecharCartao,
    ModalCheckOutAntecipado,
    ModalValorAReceberPosCheckIn,
    ModalSumarioReservasHospedes,
    ModalQuartoInfo
   },

  data() {
    return {

      teclasDigitadas: '',
      dadosHeader: {},
      busca: '',
      filtroNomeSocio: null,
      statusModoFiltro: 'filtroPeriodo',
      tituloModalAdd: 'Nova reserva',
      // tituloModalAdd: '118.400.768-36',
      acessoParaUtilizarParentesPlanosAdicionais: false,
      acessoParaUtilizarCreditos: false,
      acessoParaConcederCortesia: false,
      acessoParaInformarDesistencia: false,
      acessoParaAlterarPreReserva: false,
      acessoParaNaoRemoverReservaSemValorSinal: false,
      acessoParaOpcoesValorSinal: false,
      acessoParaCancelarCheckOut: false,
      acessoParaOpcaoBloquearEdicao: false,

      modalReservasIsShow: false,

      selectedPeriodo: null,
      periodos: [],

      dataFiltro: [],

      sedes: [],
      empresas: [],
      empresasConveniadas: [],

      desbloqueadoAteracaoHospedes: false,


      selectedMes: null,
      meses: [
          { value: '01', text: 'Janeiro' },
          { value: '02', text: 'Fevereiro' },
          { value: '03', text: 'Março' },
          { value: '04', text: 'Abril' },
          { value: '05', text: 'Maio' },
          { value: '06', text: 'Junho' },
          { value: '07', text: 'Julho' },
          { value: '08', text: 'Agosto' },
          { value: '09', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
      ],
      selectedAno: null,


      parentescoEPlanos: [
        '',
        'SÓCIO',
        'ESPOSO(A)',
        'FILHO(A)',
        'SOBRINHO(A)',
        'NETO(A)',
        'IRMÃO(Ã)',
        'GENRO',
        'NORA',
        'PAI',
        'MÃE',
        'SOGRO(A)',
        'CONVIDADO',
        'MOTORISTA',
        'CONVÊNIADO',
        'FUNCIONÁRIO',
        'DIRETOR',
        'REPRESENTANTE',
        'PET'
      ],

      parentescoEPlanosParaConvenio: [
        '',
        'CONVÊNIADO',
        'DEPENDENTE',
        'CONVIDADO',
        'PET'
      ],

      situacoes: [
        'NÃO SÓCIO',
        'SÓCIO',
        'CONVÊNIADO',
        'FUNCIONÁRIO',
        'DIRETOR',
        'REPRESENTANTE'
      ],

      listaSimNao: [
        'SIM',
        'NÃO'
      ],



      idReserva: null,
      reservaTemp: {},
      form: {
        
        colonia: null,
        cod: null,
        sede: null,
        socioCod: null,
        nome: null,
        dataFiliacao: null,
        situacao: null,
        cpf: null,
        socioHospede: null,
        deficiente: 'NÃO',
        conterCamaCasal: null,
        empresa: null,
        empresaConvenio: null,
        email: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        cep: null,
        fone: null,
        quartos: null,
        dataIn: null,
        dataOut: null,
        checkInUser: null,
        checkInData: null,
        checkOutUser: null,
        checkOutData: null,
        checkOutDias: null,
        checkOutValor: null,
        qtdDias: null,
        qtdSocios: null,
        qtdAcomp: null,
        qtdHospedes: null,
        qtdAcomodacoes: null,
        valorTotal: null,
        valorSinal: null,
        valorRestante: null,
        valorRestanteInicial: null,
        valorCreditado: null,
        status: null,
        statusUser: null,
        statusData: null,
        hospedes: []
      },

      tituloModalValorInicial: 'Valor total: ',
      comentariosOriginal: '',


      reservas: [], 
      reservasOriginal: [], 
      fieldsReservas: [
        // { key: "Tipo", label: "", sortable: true },
        { key: "cod", label: "Cód.", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "dataIn", label: "Entrada", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "qtdHospedes", label: "Hóspedes", sortable: true },
        { key: "quartos", label: "Quarto", sortable: true },
        { key: "valorTotal", label: "Valor total", sortable: true, formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        { key: "valorRestante", label: "Valor restante", sortable: true, formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        { key: "status", label: "Status", sortable: true },
        { key: "Opcoes", label: "Opções", sortable: true, thClass: 'text-center', tdClass: 'text-center' },
        { key: "hospedes", label: "Hóspedes", sortable: true, thClass: 'text-center', tdClass: 'text-center' },
        { key: "Editar", label: "", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Apagar", label: "", thClass: 'text-center', tdClass: 'text-center' },
        // "hospedes",
        // "Editar",
        // "Apagar",
      ],
      load: true,
      tituloRemoverReserva: 'Deseja remover a reserva de nº ... ?',
      tituloCheckInOutReserva: 'Confirma o check-in da reserva nº ... ?',
      checkStatus: null,
      valorTotalOriginalHospedes: 0,
      valorRestante: null,
      

      socios: [],
      fieldsSocios: [
        {key: 'cod', label: 'Cód.'},
        {key: 'nome', label: 'Nome'},
        {key: 'cpf', label: 'CPF'},
        {key: 'situacaoSocio', label: 'Situação'},
        "Selecione"
      ],
      socioSituacaoNoCadastro: null,
      loadSocios: true,


      hospedeFaturarPersonalizado: {},
      hospedesTemp: [],
      hospede: {
        nome: null, 
        cpf: null, 
        parentesco: null, 
        gratuidade: null,
        situacaoFinanceira: null,
        idade: null, 
        dataNascimento: null, 
        dataIn: null, 
        dataOut: null, 
        quantDiasReservados: null, 
        valorDiaria: null, 
        valorFinal: null, 
        dadosGratuidade: {}
      },
      
      fieldsHospedes: [
        // {key: 'nome', label: 'Nome', variant: 'danger'},
        {key: 'nome', label: 'Nome'},
        {key: 'cpf', label: 'CPF'},
        {key: 'idade', label: 'Idade'},
        {key: 'parentesco', label: 'Parentesco'},
        {key: 'gratuidade', label: 'Gratuidade'},
        {key: 'situacaoFinanceira', label: 'Sit. Financeira'},
        {key: "dataIn", label: "Entrada", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        {key: "dataOut", label: "Saida", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        {key: 'quantDiasReservados', label: 'Dias'},
        {key: 'valorDiaria', label: 'Diária', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        {key: 'valorFinal', label: 'Total', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        { key: "Menu", label: "", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Editar", label: "", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Apagar", label: "", thClass: 'text-center', tdClass: 'text-center' },
        // "Editar",
        // "Apagar",
      ],
      loadAddHospede: false,

      fieldsHospedesSubReservas: [
        // {key: 'nome', label: 'Nome', variant: 'danger'},
        {key: 'nome', label: 'Nome'},
        {key: 'cpf', label: 'CPF'},
        {key: 'idade', label: 'Idade'},
        {key: 'parentesco', label: 'Parentesco'},
        {key: 'gratuidade', label: 'Gratuidade'},
        {key: 'situacaoFinanceira', label: 'Sit. Financeira'},
        { key: "dataIn", label: "Entrada", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        {key: 'quantDiasReservados', label: 'Dias'},
        {key: 'valorDiaria', label: 'Diária', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        {key: 'valorFinal', label: 'Total', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }}
      ],


      hospedesAnteriores: [],
      fieldsHospedesAnteriores: [
        {key: 'nome', label: 'Nome.'},
        {key: 'cpf', label: 'CPF'},
        {key: 'qtd', label: 'Frequência', thClass: 'text-center', tdClass: 'text-center' },
        {key: 'Selecione', label: ''}
      ],


      fieldsReservasComGratuidade: [
        {key: 'cod', label: 'Reserva'},
        {key: 'colonia', label: 'Colônia'},
        {key: 'dataIn', label: 'Entrada'},
        {key: 'dataOut', label: 'Saida'},
        {key: 'quantDiasGratuidade', label: 'Qtd dias'}
      ],

      campoEmpresaReadonly: true,
      mascaraBuscaSocio: '',

      abaHistoricosVisivel: false,
      abaHistoricosBarVisivel: false,
      abasReservasVisivel: false,
      tabAtivaResponsavel: true,
      tabAtivaHospede: false,

      tituloModalConfirmar: '',


      idsCartaoBar: null,



      fieldsHistoricos: [
        {key: 'descricao', label: 'Descrição'},
        {key: 'userCreate', label: 'Usuário'},
        {key: 'dataCreate', label: 'Data'}
      ],

      countSumarioReserva: 0

    };
  },

  created() {

    this.form.colonia = this.$store.state.colonia 
    const user = this.$store.state.auth.user 
    // console.log('codReserva', this.codReserva)
    // console.log('user', user)

    this.acessoParaUtilizarCreditos = user.userLocal == 'Colônia' ? false : true
    this.acessoParaConcederCortesia = user.userLocal == 'Colônia' ? false : true
    this.acessoParaAlterarPreReserva = user.userLocal == 'Colônia' ? false : true
    this.acessoParaOpcoesValorSinal = user.userLocal == 'Colônia' ? false : true
    this.acessoParaCancelarCheckOut = acessoRestrito(303, false)
    this.acessoPreencherManualmente = acessoRestrito(205, false)
    this.acessoParaNaoRemoverReservaSemValorSinal = acessoRestrito(227, false)
    this.acessoParaInformarDesistencia = acessoRestrito(228, false)
    this.acessoParaOpcaoBloquearEdicao = acessoRestrito(229, false)
    

    
    this.getListasAuxiliares()
    
    this.selectedMes = dayjs().format('MM')
    this.selectedAno = Number(dayjs().format('YYYY'))

    this.acessoParaUtilizarParentesPlanosAdicionais = acessoRestrito(206, false)
    this.adicionaMaisOpcoesListaParentescoPlanos()
    
    
    this.resetForm()
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    adicionaMaisOpcoesListaParentescoPlanos() {

      if (this.acessoParaUtilizarParentesPlanosAdicionais) {

        this.parentescoEPlanos = [
          ...this.parentescoEPlanos, 
          'PERNOITE',
          'PERNOITE - CAFÉ',
          'DIÁRIA COMPLETA',
          'MEIA DIÁRIA'
        ]
      }

    },
    
    getListasAuxiliares() {
      
      api.get('reservas/listas-auxiliares')
      .then(res => {
        this.sedes = res.data.sedesLista
        this.empresas = res.data.empresasLista
        this.empresasConveniadas = res.data.empresasConveniadasLista
        this.periodos = res.data.periodosLista

        // console.log('this.empresas', this.empresas)

        this.mudaStatusModoFiltro('filtroPeriodo')
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },


    keyupBuscaSocio(evento) {

      // console.log(evento)
      if (evento.keyCode == 13 || this.filtroNomeSocio.length == 14) this.getSocios()

      this.mascaraBuscaSocio = this.filtroNomeSocio.match(/[0-9]+/) ?  '###.###.###-##' : ''
    },


    showModalSumarioReservasHospedes() {

      this.$refs["modal-sumario-reservas-hospedes"].show()

      const listaValores = []

      // Conta as reservas
      //-------------------------------------------
      const reservasChegaram = this.reservas.filter(item => item.checkInData && !item.checkOutData).length 
      const reservasSairam = this.reservas.filter(item => item.checkOutData).length
      const reservasTotais = this.reservas.filter(item => item.quartos != 0).length
      const reservasRestam = Math.abs(reservasChegaram + reservasSairam - reservasTotais)

      listaValores.push({
        descricao: 'Reservas:',
        checkIn: reservasChegaram,
        checkOut: reservasSairam,
        restam: reservasRestam,
        total: reservasTotais
      })
      //-------------------------------------------

      // Conta os hóspedes
      //---------------------------------------------
      var count = 0
      this.reservas.filter(item => item.quartos != 0)
      .forEach(item => {
        count += item.hospedes.filter(item => item.parentesco != 'PET').length
      })
      const hospedesTotais = count
      
      count = 0
      this.reservas.filter(item => item.checkInData && !item.checkOutData)
      .forEach(item => {
        count += item.hospedes.filter(item => item.parentesco != 'PET').length
      })
      const hospedesChegaram = count

      count = 0
      this.reservas.filter(item => item.checkOutData)
      .forEach(item => {
        count += item.hospedes.filter(item => item.parentesco != 'PET').length
      })
      const hospedesSairam = count

      listaValores.push({
        descricao: 'Hóspedes:',
        checkIn: hospedesChegaram,
        checkOut: hospedesSairam,
        restam: Math.abs(hospedesChegaram + hospedesSairam - hospedesTotais),
        total: hospedesTotais
      })
      //---------------------------------------------

      // Conta as reservas cadeirantes
      //-------------------------------------------
      const reservasCadeirantesTotais = this.reservas.filter(item => item.deficiente == 'SIM' && item.quartos != 0).length
      const reservasCadeirantesChegaram = this.reservas.filter(item => 
        item.deficiente == 'SIM' && item.checkInData && !item.checkOutData).length

      const reservasCadeirantesSairam = this.reservas.filter(item => item.deficiente == 'SIM' && item.checkOutData).length
      
      listaValores.push({
        descricao: 'Cadeirantes:',
        checkIn: reservasCadeirantesChegaram,
        checkOut: reservasCadeirantesSairam,
        restam: Math.abs(reservasCadeirantesChegaram + reservasCadeirantesSairam - reservasCadeirantesTotais),
        total: reservasCadeirantesTotais
      })
      //-------------------------------------------

      // Conta as reservas PETS
      //-------------------------------------------
      count = 0
      this.reservas.filter(item => item.quartos != 0)
      .forEach(item => {
        count += item.hospedes.filter(item => item.parentesco == 'PET').length
      })
      const reservasPetsTotais = count
      
      count = 0
      this.reservas.filter(item => item.checkInData && !item.checkOutData)
      .forEach(item => {
        count += item.hospedes.filter(item => item.parentesco == 'PET').length
      })
      const reservasPetsChegaram = count

      count = 0
      this.reservas.filter(item => item.checkOutData)
      .forEach(item => {
        count += item.hospedes.filter(item => item.parentesco == 'PET').length
      })
      const reservasPetsSairam = count

      listaValores.push({
        descricao: 'Pet:',
        checkIn: reservasPetsChegaram,
        checkOut: reservasPetsSairam,
        restam: Math.abs(reservasPetsChegaram + reservasPetsSairam - reservasPetsTotais),
        total: reservasPetsTotais
      })
      //-------------------------------------------


      this.countSumarioReserva = listaValores
    },


    getSocios() {

      if (!this.filtroNomeSocio) {
        this.msgNotification('Digite o nome ou CPF do sócio', 'warning', 5000)
        return
      }


      this.loadSocios = true
      
      api.get('socios/'+this.filtroNomeSocio)
      .then(res => {
        this.$refs["modal-socios"].show()
        this.socios = res.data
        this.loadSocios = false
      })
      .catch((err) => {
        this.loadSocios = false
        this.msgNotification(err.response.data, 'warning', 5000)
      });
    },

    selecionarSocio(socio) {

      if (socio.situacaoSocio != 'SÓCIO') acessoRestrito(204)


      this.form.empresa = socio.empresa
      this.form.sede = socio.sede
      this.form.socioCod = socio.cod
      this.form.nome = socio.nome
      this.form.cpf = socio.cpf
      this.form.dataNascimento = socio.dataNascimento
      this.form.cep = socio.cep
      this.form.endereco = socio.endereco
      this.form.bairro = socio.bairro
      this.form.cidade = socio.cidade
      this.form.email = socio.email
      this.form.fone = socio.fone

      // this.filtroNomeSocio = null
      this.form.situacao = socio.situacaoSocio == 'CANCELADO' ? 'NÃO SÓCIO' : socio.situacaoSocio
      this.socioSituacaoNoCadastro = socio.situacaoSocio
      this.$refs["modal-socios"].hide();

      const celularOK = validaCelular(socio.fone)
      if (!celularOK)
      this.msgNotification('Informe o número do celular corretamente.', 'info', 5000)

      this.changeSituacao()
    },



    buscarCep() {
      // console.log("cep", this.form.cep);

      this.form.endereco = null
      this.form.bairro = null
      this.form.cidade = null
      this.form.uf = null

      if (this.form.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.form.cep}/json`)
          .then((res) => {
            // console.log(res.data);

            const dadosCep = res.data;
            this.form.endereco = dadosCep.logradouro;
            this.form.bairro = dadosCep.bairro;
            this.form.cidade = dadosCep.localidade ? dadosCep.localidade+' - '+dadosCep.uf : null;
          })
          .catch((err) => this.msg("Erro: cep não encontrado.", err, "danger"));
      }

    },

    changeSituacao() {


      if (this.form.situacao == 'SÓCIO' && this.socioSituacaoNoCadastro == 'SÓCIO')
      return

      this.form.empresa = null
      this.campoEmpresaReadonly = false

      // Não permite que a situação seja colocada como sócio caso ele não estaja no cadastro como sócio 
      if (this.form.situacao == 'SÓCIO' && this.socioSituacaoNoCadastro != 'SÓCIO') {
        this.form.situacao = 'NÃO SÓCIO'
        this.campoEmpresaReadonly = true
      }

      if (this.form.situacao == 'FUNCIONÁRIO' || this.form.situacao == 'DIRETOR' || this.form.situacao == 'REPRESENTANTE') {
        this.form.empresa = 'STEFZS'
        this.campoEmpresaReadonly = true
      }   
      
      if (this.form.situacao == 'NÃO SÓCIO') {
        this.campoEmpresaReadonly = true
      }

    },
    

    async validaData() {

      if (!this.form.dataOut) return
      
      if (this.form.dataOut < this.form.dataIn) {
        this.msgNotification('A data de saida deve ser posterior a data de entrada', 'warning', 5000)
        this.form.dataOut = null
        this.form.qtdDias = null
        // console.log('4')
        return
      }
      
      
      
      const dataHoje = dayjs(new Date())
      const dataIn = dayjs(new Date(this.form.dataIn))
      const dataOut = dayjs(new Date(this.form.dataOut))
      const diferencasEmDias = dataOut.diff(dataIn, 'day')
      const diferencasEmDiasDeHoje = dataIn.diff(dataHoje, 'day')
      
      this.form.qtdDias = diferencasEmDias ? diferencasEmDias + 1 : 0

      if (this.form.dataIn == this.form.dataOut)
      this.form.qtdDias = Number(1)



      // Regra para validar que a reserva tenha até 50 dias para frente.
      if (diferencasEmDiasDeHoje > 50) {
        this.msgNotification('A reserva pode ter no máximo 50 dias de antecedência.', 'info', 7000)

        // await new Promise(r => setTimeout(r, 2000))
        // this.cancelar()
      }
    },

    async zeraDataReserva() {

      await new Promise(r => setTimeout(r, 1000))
      this.form.dataIn = dayjs().format('YYYY-MM-DD')
      this.form.dataOut = dayjs().format('YYYY-MM-DD')
    },










    //-----------------------------------------------------------------------------
    // Dados Hóspedes
    //-----------------------------------------------------------------------------

    validaDataHospede() {

      // if (!this.hospede.dataOut) return
    


      const dataIn = dayjs(new Date(this.hospede.dataIn))
      const dataOut = dayjs(new Date(this.hospede.dataOut))
      const diferencasEmDias = dataOut.diff(dataIn, 'day')
      // console.log('diferencasEmDias',diferencasEmDias)
      
      this.hospede.quantDiasReservados = diferencasEmDias ? diferencasEmDias + 1 : 0

      if (this.hospede.dataIn == this.hospede.dataOut)
      this.hospede.quantDiasReservados = Number(1)

    },



    showModalAddHospede() {


      // Verifica se a reserva está bloqueada para alterações de hóspedes.
      if (this.tituloModalAdd != 'Nova reserva') {

        if (!this.desbloqueadoAteracaoHospedes) {
          this.msgNotification('Não é possível fazer alterações de hóspedes nesta reserva. Consulte a sede central.', 'info', 7000)
          return
        }
      }


      // Exije que a data de entrada e saida estaja preenchidas
      if (!this.form.dataIn || !this.form.dataOut) {
        this.msgNotification('Informe a data de entrada e saida antes de adicionar um hóspede.', 'warning', 6000)
        return
      }

      // Exije que a data de entrada e saida estaja preenchidas
      if (this.form.dataIn > this.form.dataOut) {
        this.msgNotification('A data de entrada está posterior a data de saida.', 'warning', 6000)
        return
      }

      // Caso seja o primeiro hospede add, coloca os dados do responsavel da reserva
      if (this.form.hospedes.length == 0) {
        // console.log('entrou 14', this.form.dataNascimento)
        this.hospede.nome = this.form.nome
        this.hospede.cpf = this.form.cpf
        this.hospede.dataNascimento = dayjs(this.form.dataNascimento).format('YYYY-MM-DD')
      } 
      else {
        // console.log('entrou 20')
        this.resetHospede()
      }

      // alert(this.form.hospedes.length)

      if ((this.form.situacao == 'SÓCIO' || 
          this.form.situacao == 'DIRETOR' || 
          this.form.situacao == 'FUNCIONÁRIO' || 
          this.form.situacao == 'REPRESENTANTE') && this.form.hospedes.length == 0) {

          this.hospede.parentesco = this.form.situacao
      }

      this.hospede.dataIn = this.form.dataIn
      this.hospede.dataOut = this.form.dataOut
      this.hospede.quantDiasReservados = this.form.qtdDias



      this.loadAddHospede = false
      this.$refs['modal-add-hospede'].show()
      this.getHospedesAnteriores()
    },

    getHospedesAnteriores() {

      api.get(`reserva/hospedes-anteriores/${this.form.cpf}`)
      .then(res => {
        this.hospedesAnteriores = res.data
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'warning', 5000)
      });

    },


    selecionarHospedeJaFrequentou(hospede) {
      // console.log(hospede)

      this.hospede.nome = hospede.nome
      this.hospede.cpf = hospede.cpf
      this.hospede.parentesco = hospede.parentesco
      this.hospede.dataNascimento = hospede.dataNascimento
    },



    verificaHospede() {

      // console.log('tecla', this.hospede.cpf.length)
      
      if (this.hospede.cpf.length == 14) {
        
        // console.log('entrou 14')

        // Verifica na lista aqui no front-end
        //-------------------------------------------------
        // const hospedeExite = this.form.hospedes.filter(item => item.cpf == this.hospede.cpf)
        // if (hospedeExite.length > 0) {
        //   this.msgNotification('Um hóspede com este CPF já foi adicionado.', 'warning', 5000)
        //   return
        // }
        //-------------------------------------------------
        
        
        
        // Verifica na lista aqui no back-end
        //-------------------------------------------------
        api.get(`reserva/verifica-hospede/${this.hospede.cpf}`)
        .then(res => {

          if (!res.data) {
            this.msgNotification('O dono deste CPF, não é sócio e nunca foi à Colônia.', 'info', 5000)
            return
          }

          this.hospede.nome = res.data.nome
          this.hospede.dataNascimento = res.data.dataNascimento
          this.hospede.parentesco = res.data.parentesco
        })
        .catch((err) => {
          // console.log('err.response.data', err.response.data)
          this.msgNotification(err.response.data, 'warning', 5000)
        });
        //-------------------------------------------------
        
      } else {

        this.hospede.nome = null
        this.hospede.dataNascimento = null
        this.hospede.parentesco = null

      }

    },



    validaHospede() {

      this.loadAddHospede = true


      // Valida se já existe algum hóspede com este CPF.
      //-------------------------------------------------------------------
      // const cpfHospedeExiste = this.form.hospedes.filter((item) => item.cpf == this.hospede.cpf)
      // if (cpfHospedeExiste.length > 0) {
      //   this.loadAddHospede = false
      //   this.msgNotification('Já existe um hóspede com este CPF.', 'warning', 5000)  
      //   return
      // }
      //-------------------------------------------------------------------


      const agora = dayjs(new Date())
      const dataNascimento = dayjs(new Date(this.hospede.dataNascimento))
      const idade = agora.diff(dataNascimento, 'year')
      // const dataIn = dayjs(new Date(this.form.dataIn))
      // const dataOut = dayjs(new Date(this.form.dataOut))
      // const diferencasEmDias = dataOut.diff(dataIn, 'day')

      const contemSocioInadimplenteLista = this.form.hospedes.filter(item => item.codValor == '40. Sócio inadimplente.:')
      var contemSocioInadimplente = false

      if (contemSocioInadimplenteLista.length > 0) {
        // alert('11111111')
        contemSocioInadimplente = true
      }
      else
      if (this.hospede.parentesco != 'SÓCIO' && this.hospede.parentesco != 'ESPOSO(A)' && 
          this.hospede.parentesco != 'FILHO(A)' && this.form.hospedes.length == 0) {
        // alert('2222222222', this.form.parentesco)
        contemSocioInadimplente = true
      }

      const dados = {
        id: this.hospede.id ? this.hospede.id : null,
        idReserva: this.form.id ? this.form.id : null,
        situacao: this.form.situacao,
        empresa: this.form.empresa,
        nomeResponsavel: this.form.nome,
        cpfResponsavel: this.form.cpf,
        nome: this.hospede.nome,
        cpf: this.hospede.cpf,
        dataNascimento: this.hospede.dataNascimento,
        dataIn: this.hospede.dataIn,
        dataOut: this.hospede.dataOut,
        dataInReserva: this.form.dataIn,
        dataOutReserva: this.form.dataOut,
        parentesco: this.hospede.parentesco,
        idade: idade,
        quantDiasReservados: this.hospede.quantDiasReservados,
        socioPresente: this.form.socioPresente,
        contemSocioInadimplente: contemSocioInadimplente
        
        // hospedes: JSON.stringify(this.form.hospedes)
      }

      
      api.post(`reservas/hospedes/valida`, dados)
      .then(res => {

        this.loadAddHospede = false
        this.$refs['modal-add-hospede'].hide();
      

        // Valida tempo de carência do Sócio(6 meses) (Regra antiga)
        // const carenciaComoSocio = res.data.carenciaComoSocio
        // if (!carenciaComoSocio) {
        //   this.msgNotification('O Sócio não possui o tempo de carência nescessário.', 'warning', 6000)
        //   return  
        // }

        
        dados.dadosGratuidade = res.data.dadosGratuidade
        dados.situacaoFinanceira = res.data.situacaoFinanceira
        dados.valores = res.data.valoresDiariaETotal

        const socioComMaisDe1Ano = res.data.socioComMaisDe1Ano



        // console.log('dados', dados)
        // console.log('codValor', res.data)


        this.hospede = dados
        if (this.hospede.parentesco == 'SÓCIO' && socioComMaisDe1Ano && 
            contemSocioInadimplente == false && dados.situacaoFinanceira == 'ADIMPLENTE' ||
            this.hospede.parentesco == 'DIRETOR' ||
            this.hospede.parentesco == 'FUNCIONÁRIO' || 
            this.hospede.parentesco == 'REPRESENTANTE') 
          {

            this.$refs["modal-gratuidade"].show()
        } 
        else {
          this.addHospede('')
        }
          
        
      })
      .catch((err) => {
        // console.log('err',err)
        this.loadAddHospede = false
        this.msgNotification(err.response.data, 'warning', 5000)
      });

  
    },


    addHospede(acao) {

      this.$refs["modal-gratuidade"].hide()

      const hospede = this.hospede
      if (acao == 'usarGratuidade') {
        hospede.codValor = hospede.valores.codValor
        hospede.valorDiaria = hospede.valores.valorDiaria
        hospede.valorFinal = hospede.valores.valorFinal
        hospede.gratuidade = hospede.dadosGratuidade.gratuidade
        hospede.quantDiasGratuidade = hospede.quantDiasReservados > hospede.dadosGratuidade.diasDisponiveis 
                                      ? hospede.dadosGratuidade.diasDisponiveis
                                      : hospede.quantDiasReservados
      } 
      else
      if (acao == 'naoUsarGratuidade') {
        hospede.codValor = hospede.valores.codValor
        hospede.valorDiaria = hospede.valores.valorDiariaSemGratuidade
        hospede.valorFinal = hospede.valores.valorFinalSemGratuidade
        hospede.gratuidade = null
        hospede.quantDiasGratuidade = null
      }
      else {
        hospede.codValor = hospede.valores.codValor
        hospede.valorDiaria = hospede.valores.valorDiaria
        hospede.valorFinal = hospede.valores.valorFinal
        hospede.gratuidade = null
        hospede.quantDiasGratuidade = null
      }
      
      // console.log('hospede', hospede)

      // Remove duplicidade de hósdepe
      //------------------------------------------------------
      if (hospede.id) {
        
        this.form.hospedes.forEach((item, index) => {
          if (item.id == hospede.id) {
            this.form.hospedes.splice(index, 1)
          }
        })
        
      }
      else
      if (hospede.cpf) {
        
        this.form.hospedes.forEach((item, index) => {
          if (item.cpf == hospede.cpf) {
            this.form.hospedes.splice(index, 1)
          }
        })
        
      }
      else {
        
        this.form.hospedes.forEach((item, index) => {
          if (item.nome == hospede.nome) {
            this.form.hospedes.splice(index, 1)
          }
        })
      }
      //------------------------------------------------------

      this.form.hospedes.push(hospede)

      this.form.valorTotal += hospede.valorFinal
      // this.form.qtdHospedes = this.form.hospedes.length
      this.form.qtdHospedes = this.form.hospedes.filter(item => item.parentesco != 'PET').length
      this.form.qtdSocios = this.form.hospedes.filter(item => item.parentesco == 'SÓCIO').length
      this.form.qtdAcomp = this.form.hospedes.filter(item => item.parentesco != 'SÓCIO' && item.parentesco != 'PET').length
      this.form.qtdPet = this.form.hospedes.filter(item => item.parentesco == 'PET').length
    },


    

    async apagarHospede(hospede) {

      const user = this.$store.state.auth.user
      hospede.userCreate == user.userCreate ? acessoRestrito(282) : acessoRestrito(283)


      let msg = ''
      
      if (!this.form.id) {
        
        this.form.hospedes.forEach((item, index) => {
          if (item.nome == hospede.nome) {
            this.form.hospedes.splice(index, 1)
          }
        })
        
        msg = 'Hóspede removido.'
        
      } 
      else {
        
        this.form.hospedes.forEach((item) => {
          if (item.id == hospede.id) {
            item.remover = true
            item._rowVariant = 'danger'
          }
        })
        msg = 'O hóspede será removido assim que você confirmar a reserva.'
      }
      
      this.load = true
      this.msgNotification(msg, 'success', 6000)
      await new Promise(r => setTimeout(r, 500));
      this.load = false
    },

    editarHospede(item) {

      const user = this.$store.state.auth.user
      item.userCreate == user.userCreate ? acessoRestrito(280) : acessoRestrito(281)


      // Não permite editar o hospede caso seja insert da reserva
      if (!this.form.id) { 
        this.msgNotification('Não é possível editar o hóspede no momento da criação da reserva.', 'warning', 6000)
        return
      }
      

      this.resetHospede()
      
      this.hospede.id = item.id
      this.hospede.nomeResponsavel = this.form.nome,
      this.hospede.dataInReserva = this.form.dataIn,
      this.hospede.dataOutReserva = this.form.dataOut,
      this.hospede.nome = item.nome
      this.hospede.cpf = item.cpf
      this.hospede.parentesco = item.parentesco
      this.gratuidade = item.gratuidade
      this.situacaoFinanceira = item.situacaoFinanceira
      this.hospede.dataNascimento = dayjs(item.dataNascimento).format('YYYY-MM-DD')
      this.hospede.dataIn = dayjs(item.dataIn).format('YYYY-MM-DD')
      this.hospede.dataOut = dayjs(item.dataOut).format('YYYY-MM-DD')
      this.hospede.quantDiasReservados = item.quantDiasReservados

      this.$refs['modal-add-hospede'].show();
    },



    showFaturarPersonalizado(hospede) {

      const user = this.$store.state.auth.user
      hospede.userCreate == user.userCreate ? acessoRestrito(284) : acessoRestrito(285)

      if (this.form.status != 'RESERVADO' && this.form.status != 'PRÉ-RESERVADO' && 
          this.form.status != 'CHECK-IN HOJE') {
        this.msgNotification('Para faturar personalizado, o status deve ser RESERVADO ou PRÉ-RESERVADO.', 'warning', 8000)
        return
      }

      // console.log(hospede)
      this.hospedeFaturarPersonalizado = hospede
      this.$refs["modal-faturar-personalizado"].show();
    },


    EMITfaturarPersonalizado() {
      this.$refs["modal-faturar-personalizado"].hide();  
    },


    resetHospede() {

      this.hospede = {
        nome: null, 
        cpf: null, 
        parentesco: null, 
        gratuidade: null,
        situacaoFinanceira: null,
        idade: null, 
        dataNascimento: null, 
        dataIn: null, 
        dataOut: null, 
        quantDiasReservados: null, 
        valorDiaria: null, 
        valorFinal: null, 
        dadosGratuidade: {}
      }
    },

    mostrarCodValor(hospede) {
      // const cod| = dayjs(hospede.codValor)
      alert(hospede.codValor)
    },

    mostrarDataNascimentoHospede(hospede) {
      const dataNascimento = dayjs(hospede.dataNascimento).format('DD/MM/YYYY')
      alert(dataNascimento)
    },

    //-----------------------------------------------------------------------------
    // Fim dos dados Hóspedes
    //-----------------------------------------------------------------------------










    //-----------------------------------------------------------------------------
    // Quartos
    //----------------------------------------------------------------------------- 
    getQuartos() {

      // if (!this.form.quantos) {
      //   this.msgNotification('Não é possivel alterar o quarto agora', 'warning', 5000)
      //   return
      // }

      acessoRestrito(290)
      
      
      this.$refs["modal-quartos"].show();
    },


    EMITselecionaQuarto({quarto, qtdAcomodacoes}) {

      // console.log(quarto, qtdAcomodacoes)
      this.form.quartos = quarto
      this.form.qtdAcomodacoes = qtdAcomodacoes
      this.$refs["modal-quartos"].hide();

    },
    //-----------------------------------------------------------------------------
    // FIM Quartos
    //----------------------------------------------------------------------------- 








    //-----------------------------------------------------------------------------
    // Inicio dos dados Reservas
    //-----------------------------------------------------------------------------

    buscarReservas() {
      
      if (!this.busca) {
        this.selectedMes = dayjs().format('MM')
        this.selectedAno = Number(dayjs().format('YYYY'))
        this.getReservas()
        return
      }

      const colonia = this.$store.state.colonia 

      api.get(`reservas/${colonia}/${this.busca}`)
      .then((res) => {
        this.load = false
        this.reservas = res.data
        // this.reservasOriginal = res.data

        this.reservasOriginal = res.data.forEach(item => {

          if (item.checkInData) {
            if (acessoRestrito(202, false)){
              item.acessoEditar = true
            }
          }
          else {
            if (acessoRestrito(203, false)){
              item.acessoEditar = true
            }
            else {
              item.acessoEditar = false
            }
          }
          // console.log('reservasw',item)
          // console.log('reservas',item.cod, item.acessoEditar)
          
        })

      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },


    mudaStatusModoFiltro(modoFiltro) {
      this.statusModoFiltro = modoFiltro

      const dataHoje = dayjs().format('YYYY-MM-DD')
      // const periodos = this.periodos.reverse()

      if (this.statusModoFiltro == 'filtroPeriodo' || this.statusModoFiltro == 'filtroCalendario') {

        const periodos = this.periodos
        for (let periodo of periodos) {
  
          if (periodo.value.dataFim >= dataHoje) {
            this.selectedPeriodo = periodo.value
            break
          }
        }
  
        this.dataFiltro[0] = new Date(dayjs(this.selectedPeriodo.dataIni))
        this.dataFiltro[1] = new Date(dayjs(this.selectedPeriodo.dataFim))
      }

      
      this.getReservas()
    },

    getReservas() {
      acessoRestrito(200)

      this.busca = null

      this.load = true;
      
      const colonia = this.$store.state.colonia 
      var dataIn = null
      var dataOut = null

      // alert(this.statusModoFiltro)
      if (this.statusModoFiltro == 'filtroPeriodo') {
        
        dataIn = this.selectedPeriodo.dataIni
        dataOut = this.selectedPeriodo.dataFim
      }
      else 
      if (this.statusModoFiltro == 'filtroCalendario') {
        
        // this.dataFiltro[0] = new Date(dayjs().subtract(4, 'day').format('YYYY-MM-DD'))
        // this.dataFiltro[1] = new Date(dayjs().add(5, 'day').format('YYYY-MM-DD'))

        dataIn = dayjs(this.dataFiltro[0]).format('YYYY-MM-DD')
        dataOut = dayjs(this.dataFiltro[1]).format('YYYY-MM-DD')
      }
      else
      if (this.statusModoFiltro == 'filtroMesAno') {

        dataIn = dayjs(`${this.selectedAno}-${this.selectedMes}-01`).format('YYYY-MM-DD')
        dataOut = dayjs(dataIn).endOf('month').format('YYYY-MM-DD')
      } 

      

      // alert(this.statusModoFiltro+dataIn + dataOut)
      // console.log(dataIni, dataFim)

      
      api.get(`reservas/${colonia}/${dataIn}/${dataOut}`)
      .then((res) => {
        this.load = false
        this.reservas = res.data
        this.reservasOriginal = res.data.forEach(item => {

          if (item.checkInData) {
            if (acessoRestrito(202, false)){
              item.acessoEditar = true
            }
          }
          else {
            if (acessoRestrito(203, false)){
              item.acessoEditar = true
            }
            else {
              item.acessoEditar = false
            }
          }
          // console.log('reservasw',item)
          // console.log('reservas',item.cod, item.acessoEditar)
          
        })

        this.$refs.focusBuscar.focus()
        
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },


    showModalReserva() {
      acessoRestrito(201)

      // acaoDB == 'insert' ? acessoRestrito(1061) : acessoRestrito(1062) 
      // this.abasReservasVisivel = acaoDB != 'insert' ? true : false 

      this.tituloModalAdd = 'Nova reserva'
      this.abasReservasVisivel = false


      this.tabAtivaResponsavel = true
      this.tabAtivaHospede = false

      // Usado para saber se modal cadastro está aberto.
      this.modalReservasIsShow = true


      localStorage.removeItem('comentariosOriginal')



      this.resetForm()
      this.$refs["modal-reserva"].show();
    },
    hideModalAdd() {
      this.$refs["modal-reserva"].hide();
    },


    

    fitrarDB() {

      
      const conta = this.$store.state.conta
      // console.log('ooooooooooooooooooooooo',conta.id)
      api.get('lancamento/saldo/'+conta.id)
      .then(res => {
        this.saldo = 'Saldo disponivel: R$' + res.data
        // console.log('res.data',res.data)
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },  

    formatMoeda(event) {
      
      // console.log(event.target.value)
      let input = event.target

      var valor = input.value.replace(/,/, '')

      if (valor.length > 2)
          input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
      else   
      if (valor.length > 0 && valor.length < 4)
          input.value = valor
      else   
          input.value = ''

    },






    Editar(reserva, acao) {
      // acessoRestrito(202) 
      reserva.checkInData == true ? acessoRestrito(202) : acessoRestrito(203)

      this.form = {...reserva} // É uma cópia de conteudo do objeto para o outro.

      this.desbloqueadoAteracaoHospedes = this.form.bloquearEdicao == 'SIM' ? false : true

      this.tituloModalAdd = acao == 'editar' ? 'Atualizar reserva' : 'Visualizar reserva'
      this.abasReservasVisivel = true
      this.abaHistoricosVisivel = acessoRestrito(320, false)
      this.abaHistoricosBarVisivel = acessoRestrito(321, false)

      // Usado para saber se o modal está aberto.
      this.modalReservasIsShow = true

      // Comentários
      //----------------------------------------
      localStorage.setItem('comentariosOriginal', JSON.stringify(this.form.comentarios))
      // this.comentariosOriginal = comentarios
      // this.comentariosOriginal = [].concat(this.form.comentarios) 
      // this.comentariosOriginal = Array.from(this.form.comentarios); 
      //----------------------------------------

      // Usado para verificar mudança de valor no função:
      this.valorTotalOriginalHospedes = this.form.hospedes.reduce((total, item) => total + Number(item.valorFinal), 0)

      this.abasReservasVisivel = true
      this.$refs["modal-reserva"].show();

      // this.showModalAdd();
    },

    showModalApagarReserva(item) {
      // acessoRestrito(203)
      item.checkInData ? acessoRestrito(210) : acessoRestrito(209)

      this.tituloRemoverReserva = `Deseja remover a reserva de Nº ${item.cod} ?`,
      this.$refs["modal-apagar-reserva"].show();
      this.form = item;
    },

    apagar() {
      // acessoRestrito(1063) 

      this.$refs["modal-apagar-reserva"].hide();

      api.delete(`reservas/${this.form.id}`)
      .then(() => {
        this.msgNotification('Reserva removida.', 'success', 4000)
        this.getReservas();
      })
      .catch((err) => {

        if (err.response.status == 400)
          this.msgNotification(err.response.data, 'warning', 7000)
        else
          this.msgNotification(err.response.data, 'error', 5000)

      });
    },


    sairReceberValorInicial() {

      this.form.valorSinal = null
      this.$refs['modal-valor-inicial'].hide()
    },


    confirmar() {

      // this.form.idSede = this.$store.state.sede.id
      // this.form.idConta = this.$store.state.conta.id]

      // Verifica celular
      //###############################################################
      const celularOK = validaCelular(this.form.fone)
      if (!celularOK) {
        this.msgNotification('Informe o número do celular corretamente.', 'warning', 5000)
        return
      }
      //###############################################################



      if (!this.form.id) {
        // console.log('insertt')
        this.abasReservasVisivel == false ? this.saveFase1() : this.saveFase2()
      } 
      else {
        // console.log('updatett')

        const statusValor = this.verificaValorMaiorOuMenorPosCheckin()
        if (statusValor == 'valor maior') {
          this.$refs["modal-valorAreceber-pos-checkin"].show()
          return
        } 
        else
        if (statusValor == 'valor menor') {
          this.msgNotification('Para continuar com está alteração, será necessário cancelar o check-in.', 'warning', 7000)
          return
        }
        

        // const existeNovosHospedes = this.verificaNovosHospedesPosCheckin()
        // if (existeNovosHospedes) {
        //   this.$refs["modal-novos-hospedes-pos-checkin"].show()
        //   return
        // }
        
        this.updateReserva()
      }

      // Usado para saber se o modal está aberto.
      this.modalReservasIsShow = false
    },

    cancelar() {


      // Evita do hóspede ser removido em caso de cancelar
      this.form.hospedes.forEach(item => {
        
        delete item.remover
        delete item._rowVariant
      })


      const comentarios = JSON.parse(localStorage.getItem('comentariosOriginal'))
      this.form.comentarios = comentarios
      // this.form.comentarios = [].concat(this.comentariosOriginal) 
      // this.form.comentarios = Array.from(this.comentariosOriginal); 
      this.$refs["modal-reserva"].hide()
      this.resetForm()
      this.resetHospede()


      // Usado para saber se o modal está aberto.
      this.modalReservasIsShow = false
    },

    saveFase1() {

      api.post("reservas/fase-1", this.form)
      .then(async () => {
        
        this.abasReservasVisivel = true
        this.tabAtivaResponsavel = false
        await new Promise(r => setTimeout(r, 1000));
        this.tabAtivaHospede = true

      })
      .catch((err) => {
        if (err.response.status == 400) 
          this.msgNotification(err.response.data, 'warning', 5000)
        else
          this.msgNotification(err.response.data, 'error', 5000)
      });

    },

    saveFase2() {

      api.post("reservas/fase-2", this.form)
      .then(() => {
        
        this.abasReservasVisivel = true
        this.msgNotification('Reserva cadastrada.', 'success', 5000)
        this.$refs["modal-reserva"].hide();
        this.getReservas();
        this.verificaSeValorSinalFoiRecebido()
      })
      .catch((err) => {
        
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
          return
        }
        else
        if (err.response.status == 405) {
          // Usado para usuários que são da colônia, não pergunta do valor inicial.

          // console.log('form', this.form)
          this.form.valorSinal = 0
          this.naoReceberValorInicial()

          return
        }
        else
        if (err.response.status == 406) {
          // Usado para informar o valor inicial

          const valorTotal = this.form.valorTotal.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          });
          this.tituloModalValorInicial = `Valor total: ${valorTotal}`

          const valorSinal = (this.form.valorTotal / 100) * 30
          this.form.valorSinal = valorSinal.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'name'
          })
          .split(' ')[0]
          
          
          this.$refs["modal-valor-inicial"].show()
          return
        }
        else {
          this.msgNotification(err.response.data, 'error', 5000)
        }

      })

    },

    updateReserva() {

      api.put("reservas", this.form)
      .then(() => {
        
        this.msgNotification('Reserva atualizada.', 'success', 5000)
        this.$refs["modal-reserva"].hide();
        this.getReservas();

      })
      .catch((err) => {
        if (err.response.status == 400 || err.response.status == 406)  {

          this.msgNotification(err.response.data, 'warning', 5000)

        }
        else
          this.msgNotification(err.response.data, 'error', 5000)
      });

    },

    verificaSeValorSinalFoiRecebido() {

      const timer = setInterval(() => {
  
        // Regra aqui
        //----------------------------
        if (this.form.valorSinal == 0 && this.form.valorTotal > 0) {
          this.$refs["modal-aviso-sem-valor-sinal"].show()
        }
        //----------------------------

        clearInterval(timer)

      }, 3000)
    },

    verificaValorMaiorOuMenorPosCheckin() {

      if (this.form.checkInData && !this.form.checkOutData) {

        const valorTotalOriginal = this.valorTotalOriginalHospedes
        const valorTotalAgora = this.form.hospedes.reduce((total, item) => total + Number(item.valorFinal), 0)
  
        // console.log('valorTotalOriginal',valorTotalOriginal)
        // console.log('valorTotalAgora',valorTotalAgora)
        this.valorRestante = Number(valorTotalAgora) - Number(valorTotalOriginal)

        if (valorTotalAgora > valorTotalOriginal) return 'valor maior'
        else
        if (valorTotalAgora == valorTotalOriginal) return 'valor igual'
        else
        if (valorTotalAgora < valorTotalOriginal) return 'valor menor'
      }

      return 'valor igual'
    },

    // verificaNovosHospedesPosCheckin() {

    //   if (this.form.checkInData && !this.form.checkOutData) {
    //     const hospedesNovos = this.form.hospedes.filter(item => !item.id)

    //     if (hospedesNovos.length > 0) return true
    //   }

    //   return false
    // },

    EMIT_Reserva_updateReserva() {
      
      this.$refs["modal-valorAreceber-pos-checkin"].hide()
      this.updateReserva()

      // Usado para saber se o modal está aberto.
      this.modalReservasIsShow = false
    },


    mostrarValorCheckIn(reserva) {

      this.form = {...reserva}
      const valor = reserva.valorRestanteInicial ? reserva.valorRestanteInicial : reserva.valorRestante
      this.form.valorRestanteInicial = valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      });

      this.$refs["modal-mostrar-valor-check-in"].show()
    },



    receberValorInicial() {
      
      this.$refs["modal-valor-inicial"].hide();

      if (!this.form.id) {
        this.saveFase2()
      } else {
        this.updateValorInicial()
      }

    },
    
    naoReceberValorInicial() {
      
      this.$refs["modal-valor-inicial"].hide();
      
      if (!this.form.id) {
        this.form.valorSinal = Number(0)
        this.saveFase2()
      } else {
        this.resetForm()
      }
    },
    
    resetForm() {

      const form = this.form
      
      this.filtroNomeSocio = null
      this.socioSituacaoNoCadastro = null
      
      for (var proper in form){
        // console.log(proper + " = " + form[proper]);
        // form[proper] = 'SIMMIMIMIMIM'
        
        if (proper == 'colonia')
        continue
        
        if (proper == 'conterCamaCasal') {
          form[proper] = null
        } 
        else
        if (proper == 'deficiente') {
          form[proper] = 'NÃO'
        } 
        else
        if (proper == 'hospedes') {
          form[proper] = []
        }
        else {
          form[proper] = null
        }
      }
      
    },
    


    // Menu Reservas
    // -----------------------------------------------------------

    showModalConfirmar(reserva, textoTitulo) {

      this.$refs["modal-confirmar"].show();
      
      this.tituloModalConfirmar = textoTitulo
      this.idReserva = reserva.id
    },

    modalConfirmar() {

      this.$refs["modal-confirmar"].hide();
      
      if (this.tituloModalConfirmar == 'Confirma o cancelamento do valor inicial?') {
        acessoRestrito(220)
        this.cancelarValorInicial()
      }
      else 
      if (this.tituloModalConfirmar == 'Confirma o cancelamento da reserva?') {
        this.cancelarReserva()
      }
      else 
      if (this.tituloModalConfirmar == 'Conceder cortesia?') {
        acessoRestrito(225)
        this.concederCortesia()
      }
      else 
      if (this.tituloModalConfirmar == 'Utilizar crédito?') {
        acessoRestrito(224)
        this.utilizarCredito()
      }
      else 
      if (this.tituloModalConfirmar == 'Informar desistência?') {
        acessoRestrito(228)
        this.informarDesistencia()
      }
      else 
      if (this.tituloModalConfirmar == 'Alterar para pré-reserva?') {
        acessoRestrito(226)
        this.alterarParaPreReserva()
      }
      else 
      if (this.tituloModalConfirmar == 'Confirma não remover após 5 dias?') {
        acessoRestrito(227)
        this.naoRemoverReservaSemValorSinal()
      }
      else 
      if (this.tituloModalConfirmar == 'Deseja cancelar o check-in?') {
        acessoRestrito(302)
        this.cancelarCheckIn()
      }
      else 
      if (this.tituloModalConfirmar == 'Deseja cancelar o check-out?') {
        acessoRestrito(303)
        this.cancelarCheckOut()
      }
      else 
      if (this.tituloModalConfirmar == 'Desbloquear hóspedes para edição?') {
        acessoRestrito(229)
        this.bloqueiaOuDebloqueiaReservaParaEdicao('NÃO')
      }
      else 
      if (this.tituloModalConfirmar == 'Bloquear hóspedes para edição?') {
        acessoRestrito(229)
        this.bloqueiaOuDebloqueiaReservaParaEdicao('SIM')
      }
      
    },
    
    
    showModalCheckInOut(reserva, check) {
      check == 'check-in' ? acessoRestrito(300) : acessoRestrito(301)

      if (check == 'check-in') {
        if (reserva.status == 'CANCELADO') {
          this.msgNotification('Não é possível confirmar a entrada em uma reserva cancelada.', 'info', 1500) 
          return
        }
        if (reserva.status == 'CHECK-IN • OK' || reserva.status == 'CHECK-OUT HOJE') {
          this.msgNotification('O check-in já foi confirmado.', 'info', 1500)
          return
        } 
        if (reserva.checkOutData) {
          this.msgNotification('O check-out já foi confirmado.', 'info', 1500) 
          return
        }
      }

      if (check == 'check-out') {
        if (reserva.status == 'CANCELADO') {
          this.msgNotification('Não é possível confirmar a saida em uma reserva cancelada.', 'info', 1500) 
          return
        }
        if (reserva.status == 'CHECK-OUT • OK') {
          this.msgNotification('O check-out já foi confirmado.', 'info', 1500)
          return
        } 
        if (!reserva.checkInData) {
          this.msgNotification('O check-in ainda não já foi confirmado.', 'info', 1500) 
          return
        }
      }

      this.form = {...reserva}
      this.checkStatus = check
      const valor = reserva.valorRestante.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      });
      this.tituloCheckInOutReserva = check == 'check-in' 
      ? `Confirma o ${check} da reserva nº ${reserva.cod}, no valor à receber de ${valor} ?`
      : `Confirma o ${check} da reserva nº ${reserva.cod} ?`
      
      this.$refs["modal-check-reserva"].show();
    },

    
    checkInOutReserva() {
      
      this.$refs["modal-check-reserva"].hide();
      
      api.post(`reservas/check-in-out/${this.checkStatus}`, this.form)
      .then(async() => {
        
        this.msgNotification(`O ${this.checkStatus} foi confirmado.`, 'success', 5000)
        this.getReservas()

        if (this.checkStatus == 'check-in') {
          // await new Promise(r => setTimeout(r, 2000))
          // this.msgNotification('Informe o veículo do hóspede', 'info', 1500)
          await new Promise(r => setTimeout(r, 1500))
          this.showModalVeiculos(this.form)
        }
        
      })
      .catch((err) => {
        
        if (err.response.status == 400) {
          this.msgNotification(err.response.data, 'warning', 8000)
        }
        else
        if (err.response.status == 405) {
          this.msgNotification('Check-out antecipado, justifique.', 'info', 5000)
          this.$refs["modal-checkout-antecipado"].show()
        }
        else
        if (err.response.status == 406) {
          this.idsCartaoBar = err.response.data
          this.msgNotification('Está reserva possui cartão do bar, e deve ser fechado agora.', 'info', 5000)
          this.$refs["modal-fechar-cartao"].show()
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)          
      });
      
    },


    cancelarCheckIn() {
      
      api.put(`reservas/cancelar-check-in`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`O check-in foi cancelado.`, 'success', 5000)
        this.getReservas()
        
      })
      .catch((err) => {

        if (err.response.status == 406) {
          this.idsCartaoBar = err.response.data
          this.msgNotification('Está reserva possui cartão do bar, e deve ser fechado agora.', 'info', 5000)
          this.$refs["modal-fechar-cartao"].show()
          return
        }

        const corAlerta = err.response.status == 400 ? 'warning' : 'error'
        this.msgNotification(err.response.data, corAlerta, 5000)         
      })

    },


    cancelarCheckOut() {
      
      api.put(`reservas/cancelar-check-out`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`O check-out foi cancelado.`, 'success', 5000)
        this.getReservas()
        
      })
      .catch((err) => {
        const corAlerta = err.response.status == 400 ? 'warning' : 'error'
        this.msgNotification(err.response.data, corAlerta, 5000)         
      })

    },

    bloqueiaOuDebloqueiaReservaParaEdicao(acao) {
      
      api.put(`reservas/bloqueia-ou-debloqueia-reserva-para-edicao`, { idReserva: this.idReserva, acao: acao })
      .then((res) => {
        
        this.msgNotification(res.data, 'success', 5000)
        this.getReservas()
        
      })
      .catch((err) => {
        const corAlerta = err.response.status == 400 ? 'warning' : 'error'
        this.msgNotification(err.response.data, corAlerta, 5000)         
      })

    },

    EMITfecharCartao() {
      this.$refs["modal-fechar-cartao"].hide()
      this.checkInOutReserva()
    },

    EMITjustificaCheckoutAntecipado_confirmar(observacao) {
      this.form.checkoutAntecipadoJustificado = observacao

      this.$refs["modal-checkout-antecipado"].hide()
      this.checkInOutReserva()
    },

    EMITjustificaCheckoutAntecipado_cancelar() {
      
      delete this.form.checkoutAntecipadoJustificado
      this.$refs["modal-checkout-antecipado"].hide()
    },



    menuReceberValorInicial(reserva) {

      if (reserva.status == 'AUSENTE' || reserva.status == 'PRÉ-AUSENTE') {
        this.msgNotification(`Não é possivel receber o valor inicial em reserva com status ${reserva.status}`, 'warning', 5000)
        return
      }

      this.form = {...reserva}
      const valorSinal = (reserva.valorTotal / 100) * 30
      this.form.valorSinal = parseFloat(valorSinal).toFixed(2)



      const valorTotal = reserva.valorTotal.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      });
      this.tituloModalValorInicial = `Valor total: ${valorTotal}`
      this.$refs["modal-valor-inicial"].show();
    },

    updateValorInicial() {

      this.$refs["modal-check-reserva"].hide();
      
      api.put(`reservas/valor-inicial`, { idReserva: this.form.id, valorSinal: this.form.valorSinal })
      .then(() => {
        
        this.msgNotification(`O valor inicial foi confirmado.`, 'success', 5000)
        this.getReservas();
        
      })
      .catch((err) => {
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)         
      });
      
    },

    cancelarValorInicial() {

      api.put(`reservas/cancela-valor-inicial`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`O valor inicial foi cancelado.`, 'success', 5000)
        this.getReservas();
        
      })
      .catch((err) => {
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)         
      });
      
    },

    cancelarReserva() {

      api.put(`reservas/cancela-reserva`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`A reserva foi cancelada.`, 'success', 5000)
        this.getReservas();
        
      })
      .catch((err) => {
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)         
      });
      
    },

    concederCortesia() {

      api.put(`reservas/conceder-cortesia`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`Cortesia concedida.`, 'success', 5000)
        this.getReservas();
        
      })
      .catch((err) => {
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)         
      });
      
    },

    informarDesistencia() {

      api.put(`reservas/informar-desistencia`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`Desistência confirmada.`, 'success', 5000)
        this.getReservas();
        
      })
      .catch((err) => {
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)         
      });

    },
    
    showModalCreditos(reserva) {

      // console.log(reserva.status, reserva.status != 'RESERVADO' || reserva.status != 'PRÉ-RESERVADO')
      
      if (reserva.status != 'RESERVADO' && reserva.status != 'PRÉ-RESERVADO' && reserva.status != 'CHECK-IN HOJE') {
        this.msgNotification('Para aplicar o crédito, o status deve ser RESERVADO ou PRÉ-RESERVADO.', 'warning', 8000)
        return
      }
      

      this.form = {...reserva}
      this.$refs["modal-creditos"].show();
    },

    EMITaplicarCredito() {
      this.$refs["modal-creditos"].hide();
      this.resetForm()
      this.getReservas()
    },



    alterarParaPreReserva() {

      api.put(`reservas-alterar-status-prereserva`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`Status alterado para PRÉ-RESERVADO.`, 'success', 5000)
        this.getReservas();
        
      })
      .catch((err) => {
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)         
      });

    },



    naoRemoverReservaSemValorSinal() {

      api.put(`reservas-alterar-status-nao-remover`, { idReserva: this.idReserva })
      .then(() => {
        
        this.msgNotification(`A reserva não será removida após 5 dias.`, 'success', 5000)
        this.getReservas();
        
      })
      .catch((err) => {
        if (err.response.status == 400)  {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
        else
        this.msgNotification(err.response.data, 'error', 5000)         
      });

    },
    


    showModalVeiculos(reserva) {

      this.reservaTemp = {...reserva}
      this.$refs['modal-veiculos'].show()

    },


    exibeInfosQuarto(reserva) {
      // console.log('ooooooooooooooooo', reserva)
      this.reservaTemp = {...reserva}
      this.$refs['modal-quarto-info'].show()
    },







    
    
    

    // IMPRESSÕES
    //----------------------------------------------------------------------
    
    imprimirReserva(reserva) {

      this.reservaTemp = reserva
      // console.log(reserva)
      
      this.$refs["modal-imprimir-reserva"].show();  
    },
    
    //----------------------------------------------------------------------


    

    iniciaTimerTeclas() {
            
      const timer = setInterval(() => {

        this.teclasDigitadas = '' 

        clearInterval(timer)

      }, 1000)

    },
    // -----------------------------------------------------------

  },


  mounted() {

    window.addEventListener("keypress", e => {
      // console.log(String.fromCharCode(e.keyCode));
      // console.log(e);
      if (this.modalReservasIsShow) return


      const teclaPres = String.fromCharCode(e.keyCode)

      // if (this.teclasDigitadas.length == 0) {
      //   this.buscarReservas()
      //   return 
      // }

      if(teclaPres.match(/[0-9]+/)) {
        this.teclasDigitadas += teclaPres
        this.iniciaTimerTeclas()
      }

      
      if (e.keyCode == 13) {
        this.$refs.focusBuscar.focus()
        this.busca = this.teclasDigitadas
        this.buscarReservas()
        this.teclasDigitadas = ''
        this.busca = ''
      }
      
      // console.log('this.teclasDigitadas',this.teclasDigitadas);
    });

  },

  computed: {

    // filtrarItem() {
    //   if (this.search.trim() == '') return this.items
          
    //   const search = this.search.toLowerCase()
    //   const listaFiltrada = []
    //   this.items.forEach(item => {
    //     if(item.idCliente.toString().match(search)) listaFiltrada.push(item) 
    //     if(item.nome.toLowerCase().match(search)) listaFiltrada.push(item) 
    //     if(item.email.toLowerCase().match(search)) listaFiltrada.push(item) 
    //     if(item.endereco.toString().match(search)) listaFiltrada.push(item) 
    //   })

    //   return listaFiltrada
    // },


      // filtrar() {

      //   // if (this.filtro.length > 0 && this.filtro.length < 4) return this.items

      //   const busca = this.filtro.toString().toLowerCase()

      //     if (busca.trim() == '') return this.reservas
              
      //     let listaFiltrada = []
      //     this.reservas.forEach((item) => {
      //       if(item.seq.toString().match(busca)) listaFiltrada.push(item) 
      //       if(item.fornecedor.toLowerCase().match(busca)) listaFiltrada.push(item) 
      //       if(item.documento && item.documento.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
      //       if(item.vencimento.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
      //       if(item.baixaData && item.baixaData.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
      //       if(item.categoria.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
      //       if(item.valor.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
      //     })

      //     return [...new Set(listaFiltrada)]
      // },
  },

  
};